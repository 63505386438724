var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"about pa-12"},[_c('h1',[_vm._v("Historique des E/S")]),_c('div',[_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.dates,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.dates=$event},"update:return-value":function($event){_vm.dates=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Filtrer une periode","prepend-icon":"mdi-calendar","readonly":"","clearable":""},on:{"click:clear":_vm.clearDates},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-date-picker',{attrs:{"range":"","scrollable":""},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialog.save(_vm.dates)}}},[_vm._v(" OK ")])],1)],1)],1),_c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.getFullHistory.reverse(),"items-per-page":10,"custom-sort":_vm.customSort},scopedSlots:_vm._u([{key:"item.inTs",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.inTime)+" ")]}},{key:"item.outTs",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.outTime)+" ")]}}])}),(_vm.showLoadMore)?_c('v-btn',{staticStyle:{"margin-top":"12px"},attrs:{"depressed":"","color":"primary"},on:{"click":_vm.getMoreHistoryEvent}},[_vm._v(" Charger plus de données ")]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }