---
Here we need to:
1. get HOMES & USERS to compute user with his company name
2. Match the input & output per badgeId
3. Add user information like email and company name
Finally the data injected in the table is:
{
  inTime: '02/04/21 12:26',
  outTime: '02/04/21 16:21',
  user: {
    email: "toto@test.com",
    company: "mysuperCompany",
  },
  in: {
    "_id": "60670e193c501f4cffcbb499",
    "logTs": "2021-04-02T12:26:53Z",
    "openingId": null,
    "badgeId": "abababab-4fa2-4e98-8024-bc5b71e08905:0:43",
    "event": "badgeIn",
  },
  out: {
    "_id": "6067452d88c39957a2533427",
    "logTs": "2021-04-02T16:21:53Z",
    "openingId": null,
    "badgeId": "abababab-4fa2-4e98-8024-bc5b71e08905:0:43",
    "event": "badgeOut",
    "__typename": "HistoryEvent"
  },
}
---

<template>
  <div class="about pa-12">
    <!-- <p>getUserWithCompanyName: {{ getUserWithCompanyName }}</p> -->
    <!-- <p>getFullHistory: {{ getFullHistory }}</p> -->
    <!-- <p>E/S: {{ allHistory }}</p> -->
    <h1>Historique des E/S</h1>
    <div>
      <v-dialog
        ref="dialog"
        v-model="modal"
        :return-value.sync="dates"
        persistent
        width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dateRangeText"
            label="Filtrer une periode"
            prepend-icon="mdi-calendar"
            readonly
            clearable
            v-bind="attrs"
            v-on="on"
            @click:clear="clearDates"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="dates"
          range
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="modal = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.dialog.save(dates)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-dialog>
    </div>
    <div>
      <v-data-table
        :headers="headers"
        :items="getFullHistory.reverse()"
        :items-per-page="10"
        :custom-sort="customSort"
        class="elevation-1"
      >
        <template v-slot:item.inTs="{ item }">
          {{ item.inTime }}
        </template>
        <template v-slot:item.outTs="{ item }">
          {{ item.outTime }}
        </template>
      </v-data-table>
      <v-btn
        v-if="showLoadMore"
        style="margin-top: 12px"
        depressed
        color="primary"
        @click="getMoreHistoryEvent"
      >
        Charger plus de données
      </v-btn>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import USERS from '../graphQL/Users.gql';
import HOMES from '../graphQL/Homes.gql';

const ITEMSCALL = 2000;

export default {
  data: () => ({
    // date: new Date().toISOString().substr(0, 10),
    dates: [],
    menu: false,
    modal: false,
    menu2: false,
    headers: [
      {
        text: 'Entrée',
        align: 'start',
        value: 'inTs',
      },
      { text: 'Sortie', value: 'outTs' },
      { text: 'Utilisateur', value: 'user.email' },
      { text: 'Entreprise', value: 'user.company' },
    ],
    showLoadMore: true,
    allHistory: [],
    historyFiltered: [],
    isHistoryReady: false,
    es: [],
  }),
  created() {
    this.getHistoryEvent();
  },
  apollo: {
    users: USERS,
    homes: HOMES,
  },
  computed: {
    dateRangeText() {
      return this.dates.join(' ~ ');
    },
    getFullHistory() {
      if (this.users && this.homes && this.homes.length > 0) {
        return this.computeInputOutpout(this.allHistory, this.getUserWithCompanyName, this.dates);
      }
      return [];
    },
    getUserWithCompanyName() {
      const userWithCompanyName = [];
      if (this.users && this.homes && this.homes.length > 0) {
        this.users.forEach((user) => {
          const company = this.homes[0].companies.filter((c) => c.companyId === user.companyId);
          const userComplete = user;
          userComplete.companyName = company && company[0] ? company[0].name : 'unknown';
          userWithCompanyName.push(userComplete);
        });
      }
      return userWithCompanyName;
    },
  },
  methods: {
    async getUsers() {
      const historyEvent = await this.$apollo.query({
        query: gql`
          query {
          users {
            _id
            logTs
            openingId
            badgeId
            event
          }
        }`,
        // variables: {
        //   query: {
        //     event: ['badgeIn', 'badgeOut'],
        //   },
        // },
        fetchPolicy: 'network-only',
      });
      this.allHistory = historyEvent.data.historyEvents;
      this.isHistoryReady = true;
      return historyEvent.data.historyEvents;
    },

    async getHistoryEvent() {
      const historyEvent = await this.$apollo.query({
        query: gql`
          query {
          historyEvents(
            query: { OR: [{event: "badgeIn"}, {event: "badgeOut"}]},
            sortBy: LOGTS_DESC,
            limit: ${ITEMSCALL}
          ) {
            _id
            logTs
            openingId
            badgeId
            event
          }
        }`,
        fetchPolicy: 'network-only',
      });
      this.showLoadMore = historyEvent.data.historyEvents.length === ITEMSCALL;
      this.allHistory = historyEvent.data.historyEvents;
      this.isHistoryReady = true;
      return historyEvent.data.historyEvents;
    },

    async getMoreHistoryEvent() {
      const offset = this.allHistory[this.allHistory.length - 1]._id;
      const historyEvent = await this.$apollo.query({
        query: gql`
          query {
          historyEvents(
            query: { _id_lt: "${offset}", OR: [{event: "badgeIn"}, {event: "badgeOut"}]},
            sortBy: LOGTS_DESC,
            limit: 100
          ) {
            _id
            logTs
            openingId
            badgeId
            event
          }
        }`,
        fetchPolicy: 'network-only',
      });
      console.log('More historyEvent: ', historyEvent);
      this.showLoadMore = historyEvent.data.historyEvents.length === ITEMSCALL;
      this.allHistory = [...this.allHistory, ...historyEvent.data.historyEvents];
      return historyEvent.data.historyEvents;
    },

    computeInputOutpout(history, userInfo, periodToMatch) {
      // -- Clean history by deleting duplicated data. Element with same
      const deleteDuplicated = (coll) => {
        let copyCollection = [...coll];
        const cleanCollection = [];

        while (copyCollection.length > 0) {
          const x = copyCollection.shift();
          cleanCollection.push(x);
          copyCollection = copyCollection.filter((elm) => !(elm.logTs === x.logTs
            && elm.badgeId === x.badgeId
            && elm.event === x.event));
        }
        return cleanCollection;
      };
      const cleanHistory = deleteDuplicated(history);
      const start = new Date(periodToMatch[0]).valueOf();
      // -- Current timestamp in epoch + Add 24H to get the current day
      const end = new Date(periodToMatch[1]).valueOf() + (3600000 * 24);
      const data = cleanHistory.reverse().filter((inOut) => {
        if (periodToMatch.length === 0) {
          return inOut;
        }
        const currentInOutTime = new Date(inOut.logTs).valueOf();
        return currentInOutTime >= start && currentInOutTime <= end;
      });
      const inputsOutputs = [];
      userInfo.forEach((user) => {
        const dataUser = data.filter((elem) => elem.badgeId === user.badgeNumber.uuid);
        let line = {};
        dataUser.forEach((dataLog) => {
          if (dataLog.event === 'badgeIn') {
            const inTime = dataLog.logTs ? this.readableDateFromStringDate(dataLog.logTs) : '';
            const inTs = dataLog.logTs ? dataLog.logTs : null;
            line.inTime = inTime;
            line.inTs = inTs;
          } else if (dataLog.event === 'badgeOut') {
            if (line.inTs) {
              const outTime = dataLog.logTs ? this.readableDateFromStringDate(dataLog.logTs) : '';
              const outTs = dataLog.logTs ? dataLog.logTs : null;
              line.outTime = outTime;
              line.outTs = outTs;
              line.user = {
                email: user.email,
                company: user.companyName,
              };
              inputsOutputs.push(line);
            }
            line = {};
          }
        });
        if (line.inTs) {
          line.user = {
            email: user.email,
            company: user.companyName,
          };
          inputsOutputs.push(line);
        }
      });
      // -- Iterate over the history to find IN/Out for each badge
      // while (data.length > 0) {
      //   let i = 0;

      //   // -- This is the main row informations for the table
      //   let input = null;
      //   let output = null;
      //   let matchIndex = null;
      //   let badgeIdToMatch = null;

      //   // -- We have the case where the first badge is not an input so set the output
      //   if (data[i].event === 'badgeIn') {
      //     input = data[i];
      //   } else {
      //     output = data[i];
      //   }

      //   // -- Match the output if we have an input
      //   if (input !== null) {
      //     badgeIdToMatch = input.badgeId;
      //     output = data.find((d) => d.event === 'badgeOut' && d.badgeId === input.badgeId);
      //     matchIndex = data.findIndex((d) => d.event === 'badgeOut'
      // && d.badgeId === input.badgeId);
      //   } else {
      //     badgeIdToMatch = output.badgId;
      //   }

      //   // -- We need user information with the badge id
      //   const userMatch = userInfo.find((user) => user?.badgeNumber?.uuid === badgeIdToMatch);
      //   // -- Compute time to be readable
      //   const inTime = input && input.logTs ? this.readableDateFromStringDate(input.logTs) : '';
      //   const outTime = output && output.logTs ?
      //  this.readableDateFromStringDate(output.logTs):'';
      //   const inTs = input && input.logTs ? input.logTs : null;
      //   const outTs = output && output.logTs ? output.logTs : null;
      //   // -- Finally add the match founded
      //   inputsOutputs.push({
      //     inTime,
      //     outTime,
      //     inTs,
      //     outTs,
      //     user: {
      //       email: userMatch ? userMatch.email : 'no email',
      //       company: userMatch ? userMatch.companyName : 'no company',
      //     },
      //   });

      //   // -- Delete the match to avoid duplicate
      //   data.splice(matchIndex, 1);
      //   data.shift();
      //   i += 1;
      // }
      return inputsOutputs.sort((a, b) => a.inTs > b.inTs);
    },
    clearDates() {
      this.dates = [];
    },
    readableDateFromStringDate(strDate) {
      const dateFormat = new Date(strDate);
      let d = dateFormat.getDate();
      if (d < 10) { d = `0${d}`; }
      let m = dateFormat.getMonth() + 1;
      if (m < 10) { m = `0${m}`; }
      const y = dateFormat.getFullYear();
      let h = dateFormat.getHours();
      if (h < 10) { h = `0${h}`; }
      let mn = dateFormat.getMinutes();
      if (mn < 10) { mn = `0${mn}`; }
      return `${d}/${m}/${y} ${h}:${mn}`;
    },
    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        if (index[0] === 'inTs' || index[0] === 'outTs') {
          if (!isDesc[0]) {
            return new Date(b[index]) - new Date(a[index]);
          }
          return new Date(a[index]) - new Date(b[index]);
        }
        if (typeof a[index] !== 'undefined') {
          if (!isDesc[0]) {
            return a[index]?.toLowerCase().localeCompare(b[index].toLowerCase());
          }
        }
        return b[index]?.toLowerCase().localeCompare(a[index].toLowerCase());
      });
      return items;
    },
  },
};
</script>
